import React from 'react';
import Image from 'reusecore/src/elements/Image';
import DocumentationSection from '../../../../../containers/SaasModern/Documentation';
import example_year from '../../../../../../../common/src/assets/image/2020/year_month_columns.png';
import template_options_year from '../../../../../../../common/src/assets/image/template_options_year_months_as_columns.png';

const Documentation = () => {
  const content = (
     <div>
       <p>
         The Year (Months as Columns) template style generates a calendar showing 12 months on page, with each month as a column and the days as rows. Days that contain events or tasks will be color-coded for up to 5 items.
       </p>
       <p>
         <Image alt="" src={example_year} style={{ width: 914 }} />
       </p>
       <h2>Template Settings</h2>
       <p>
         The Year (Months as Columns) template provides the following options when creating a calendar...
       </p>
       <Image alt="" src={template_options_year} style={{ width: 448 }} />
       <br />
       <table className="table">
         <tbody>
         <tr>
           <td>
             <strong>Start month</strong>
           </td>
           <td>The first month to be printed. This can be any month of the year. It doesn't need to start with January.</td>
         </tr>
         </tbody>
       </table>
     </div>
  );

  return (
     <DocumentationSection
        url="https://www.printablecal.com/Documentation/Create-Calendar/Templates-and-Options/Year-Months-As-Columns"
        commentsId="commentsplus_year_months_as_columns"
        title="Year (Months as Columns) Template"
        description="The Year (Months as Columns) template style generates a calendar showing an entire year with each month as a column and the days as rows. Days that contain events or tasks will be color-coded for up to 5 items."
        keywords="year template, month columns, 2020 template, calendar template, print 2020, print calendar, print holidays, 2020 holidays, Excel calendar, Word calendar"
        content={content}
     />
  );
};

export default Documentation;
